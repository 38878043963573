import React from 'react';
import QuillEditor from "./Editor/QuillEditor";
import WizSunEditor from "./Editor/SunEditor";
import WizEditor from "./Editor/WizEditor";

function App() {
    function handler(value:string)
    {
        console.log(value);
    }
  return (
      <WizSunEditor style={{width:"100%", minHeight:"700px"}} OnChange={handler}/>
  );
}

export default App;
