import React, {CSSProperties, useEffect, useRef, useState} from "react";
import suneditor from 'suneditor'
import 'suneditor/dist/css/suneditor.min.css';
import { SunEditorOptions } from "suneditor/src/options";
import plugins, {blockquote} from 'suneditor/src/plugins'
import SunEditor from "suneditor/src/lib/core";
import lang from 'suneditor/src/lang'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import './customPlugin/css/drawImage.css'
import drawImageEx from "./customPlugin/drawImageEx";
import imageEx from "./customPlugin/imageEx";

import align from "suneditor/src/plugins/submenu/align";
import font from "suneditor/src/plugins/submenu/font";
import fontSize from "suneditor/src/plugins/submenu/fontSize";
import fontColor from "suneditor/src/plugins/submenu/fontColor";
import hiliteColor from "suneditor/src/plugins/submenu/hiliteColor";
import math from "suneditor/src/plugins/dialog/math";
import list from "suneditor/src/plugins/submenu/list";
import table from "suneditor/src/plugins/submenu/table";
import formatBlock from "suneditor/src/plugins/submenu/formatBlock";
import lineHeight from "suneditor/src/plugins/submenu/lineHeight";
import template from "suneditor/src/plugins/submenu/template";
import paragraphStyle from "suneditor/src/plugins/submenu/paragraphStyle";


interface SizeProps {
    width?:string,
    height?:string,
    minWidth?:string,
    maxWidth?:string,
    minHeight?:string,
    maxHeight?:string,
    toolbarWidth?:string
}

interface EditorProps {
    style?: SizeProps;
    OnChange?:(value:string)=>void,

}

const WizSunEditor: React.FC<EditorProps> = ({ style, OnChange }) => {
    const editorRef = useRef<HTMLTextAreaElement>(null);
    let editorInstance: SunEditor | null;

    const parentRef = useRef<HTMLDivElement>(null); // 부모 컴포넌트를 위한 ref 생성
    const [parentHeight, setParentHeight] = useState<number>(0); // 부모 높이 저장을 위한 상태


    useEffect(() => {
        // 컴포넌트가 마운트 된 후에 부모 컴포넌트의 높이를 측정
        if (parentRef.current) {
            let width = parentRef.current.offsetWidth;
            let height = parentRef.current.offsetHeight;
            console.log("before : " + height);
            if (width <= 412)
            {
                height = height - 170.4 - 40;
            }
            else  if (width > 412 && width <= 488)
            {
                height = height - 128.8 - 40;
            }
            else
            {
                height = height - 45.6 - 40;
            }
            console.log("after : " + height);
            setParentHeight(height);


        }
    }, [parentHeight]); // 빈 의존성 배열을 전달하여 컴포넌트 마운트 시에만 실행되도록 함

    useEffect(() => {
        if (parentHeight > 0)
        {
            initializeSunEditor();
        }
        return () => {
            destroySunEditor();
        };
    }, [parentHeight]);


    function handleTextValue(textValue:string)
    {
        if (OnChange)
        {
            //setText(event.target.value);
            OnChange(textValue);
        }

    }

    function calculatePercentage(totalSize: string, percent: number): string {
        // 숫자 추출을 위한 정규식
        const regex = /(\d*\.?\d+)(px|em|%|\b)/g;

        // 스타일에서 숫자와 단위 추출
        const matches = totalSize.match(regex);
        let result = 'auto';
        if (matches) {
            for (const match of matches) {
                const [valueStr, unit] = match.match(/[\d.]+|\D+/g) || []; // 숫자와 단위 분리

                const totalvalue = parseFloat(valueStr ? valueStr : "100"); // 숫자
                const newValue: number = totalvalue * percent / 100;
                // 결과 문자열에 다시 단위를 붙여서 추가
                result = newValue.toFixed(2) + unit;
            }
        }

        return result;
    }

    const initializeSunEditor = () => {
        if (editorRef.current) {
            console.log(parentHeight);
            const editorOption: SunEditorOptions = {
                plugins: {font, fontSize, fontColor, hiliteColor,
                    blockquote, list,
                    align, lineHeight,
                    table, math,
                    imageEx, drawImageEx},
                width:style?.width ? style?.width : "auto",
                height:`${parentHeight}px`,
                minWidth:style?.minWidth ? style?.minWidth : "auto",
                minHeight:`${parentHeight}px`,
                maxWidth:style?.maxWidth ? style?.maxWidth : "auto",
                maxHeight:style?.maxHeight ? style?.maxHeight : "auto",
                //imageWidth:style?.width ? calculatePercentage(style?.width, 40) : "auto",
                imageHeight:style?.minHeight ? calculatePercentage(style?.minHeight, 90): "auto",
                //imageSizeOnlyPercentage:true,
                toolbarWidth:style?.toolbarWidth ? style?.toolbarWidth : "auto",
                buttonList: [
                    /**['undo', 'redo'],*/
                    ['font', 'fontSize', /*'formatBlock'*/],
                    ['bold', 'underline', 'italic', 'strike', 'fontColor', 'hiliteColor'],
                    //[/*'textStyle'*/],
                    [/*'horizontalRule','paragraphStyle', */'blockquote',  'list'],
                    ['align', 'lineHeight', 'outdent', 'indent', ],
                    ['table', 'math', 'subscript', 'superscript'/**'video', 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                    [
                        {
                            name: 'imageEx',
                            dataDisplay: 'dialog',
                            title: 'imageEx',
                            buttonClass:'',
                            innerHTML: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.75 15.77"><g><path d="M8.77,8.72a.88.88,0,0,1-.61-.27.82.82,0,0,1-.25-.61.89.89,0,0,1,.25-.62A.82.82,0,0,1,8.77,7a.81.81,0,0,1,.61.25.83.83,0,0,1,.27.62.81.81,0,0,1-.25.61.91.91,0,0,1-.63.27Zm9.62-5a1.74,1.74,0,0,1,1.76,1.76V17.76a1.74,1.74,0,0,1-1.76,1.76H6.16A1.74,1.74,0,0,1,4.4,17.76V5.51A1.74,1.74,0,0,1,6.16,3.75H18.39Zm0,1.75H6.16v8L8.53,11.8a.94.94,0,0,1,.54-.17.86.86,0,0,1,.54.2L11.09,13l3.64-4.55a.78.78,0,0,1,.34-.25.85.85,0,0,1,.42-.07.89.89,0,0,1,.39.12.78.78,0,0,1,.28.29l2.24,3.67V5.51Zm0,12.24V15.6L15.3,10.53,11.89,14.8a.89.89,0,0,1-.59.32.82.82,0,0,1-.64-.18L9,13.62,6.16,15.74v2Z" transform="translate(-4.4 -3.75)"></path></g></svg>`
                        },
                        {
                            name: 'drawImageEx',
                            dataDisplay: 'dialog',
                            title: 'drawImageEx',
                            buttonClass:'',
                            innerHTML:`<svg viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.9120000000000001"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0992 2.39109C15.6207 0.869636 18.0875 0.869636 19.6089 2.39109C21.1304 3.91255 21.1304 6.37932 19.6089 7.90077L12.0149 15.4948C11.5869 15.9228 11.3255 16.1842 11.0342 16.4115C10.691 16.6792 10.3196 16.9087 9.92667 17.096C9.59315 17.2549 9.24244 17.3718 8.66818 17.5632L5.99553 18.4541L5.35386 18.668C4.77951 18.8594 4.14629 18.7099 3.7182 18.2818C3.2901 17.8537 3.14062 17.2205 3.33207 16.6462L4.43683 13.3318C4.62822 12.7576 4.7451 12.4069 4.90405 12.0734C5.09132 11.6804 5.32084 11.309 5.58856 10.9658C5.81578 10.6745 6.07719 10.4131 6.50525 9.98509L14.0992 2.39109ZM5.96092 16.8845L5.11556 16.0391L5.84398 13.8538C6.05606 13.2176 6.14366 12.9589 6.25814 12.7187C6.39854 12.4241 6.57061 12.1457 6.77132 11.8883C6.93497 11.6785 7.12718 11.4845 7.60141 11.0102L13.4924 5.11927C13.7355 5.72927 14.147 6.46549 14.8408 7.15927C15.5345 7.85306 16.2708 8.26447 16.8807 8.50764L10.9898 14.3986C10.5155 14.8728 10.3215 15.065 10.1117 15.2287C9.85436 15.4294 9.57594 15.6015 9.28134 15.7419C9.04113 15.8564 8.78244 15.944 8.14619 16.156L5.96092 16.8845ZM18.076 7.31241C17.9521 7.28522 17.7973 7.24432 17.6213 7.18325C17.1373 7.01532 16.5006 6.69778 15.9014 6.09861C15.3022 5.49944 14.9847 4.86276 14.8168 4.37873C14.7557 4.2027 14.7148 4.04787 14.6876 3.92404L15.1599 3.45175C16.0956 2.51608 17.6126 2.51608 18.5483 3.45175C19.4839 4.38742 19.4839 5.90444 18.5483 6.84011L18.076 7.31241ZM3.25002 22C3.25002 21.5858 3.58581 21.25 4.00002 21.25H20V22.75H4.00002C3.58581 22.75 3.25002 22.4142 3.25002 22Z" fill="#000000"></path> </g></svg>`
                        },
                        'link' ,
                    ],
                    /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                    /*['preview', 'print'],
                    /**['fullScreen', 'showBlocks', 'codeView'],*/
                   /** ['save', 'template'],*/
                ],
                katex:katex,
                lang:lang.ko,
                defaultStyle: 'font-family: Malgun Gothic; font-size: 13px;text-align:justify',
                font: ['맑은 고딕', '돋움체'],
                imageRotation: true,
                fontSize: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 24, 28, 30, 42, 50, 64],
                colorList: [
                    "#FFFFFF"  ,"#000000" ,"#44546A" ,"#4472C4" ,"#ED7D31" ,"#A5A5A5" ,"#FFC000" ,"#5B9BD5" ,"#70AD47"
                    ,"#F2F2F2" ,"#D0CECE" ,"#D6DCE4" ,"#D9E2F3" ,"#FBE5D5" ,"#EDEDED" ,"#FFF2CC" ,"#DEEBF6" ,"#E2EFD9"
                    ,"#D8D8D8" ,"#AEABAB" ,"#ADB9CA" ,"#B4C6E7" ,"#F7CBAC" ,"#DBDBDB" ,"#FEE599" ,"#BDD7EE" ,"#C5E0B3"
                    ,"#BFBFBF" ,"#757070" ,"#8496B0" ,"#8EAADB" ,"#F4B183" ,"#C9C9C9" ,"#FFD965" ,"#9CC3E5" ,"#A8D08D"
                    ,"#A5A5A5" ,"#3A3838" ,"#323F4F" ,"#2F5496" ,"#C55A11" ,"#7B7B7B" ,"#BF9000" ,"#2E75B5" ,"#538135"
                    ,"#7F7F7F" ,"#171616" ,"#222A35" ,"#1F3864" ,"#833C0B" ,"#525252" ,"#7F6000" ,"#1E4E79" ,"#375623"
                    ,"#C00000" ,"#FF0000" ,"#FFC000" ,"#FFFF00" ,"#92D050" ,"#00B050" ,"#00B0F0" ,"#0070C0" ,"#7030A0"
                ],
            }

            editorInstance = suneditor.create(editorRef.current, editorOption);

            editorInstance.onChange = function (contents:string, core)
            {
                handleTextValue(contents);
            }
        }
    }

    const destroySunEditor = () => {
        if (editorInstance) {
            editorInstance.destroy();
            editorInstance = null;
        }
    }


    return (
        <div ref={parentRef} style={{height:"100%"}}>
        <textarea ref={editorRef} id="wizWriteNote"></textarea>
        </div>
    );
}

export default WizSunEditor;
